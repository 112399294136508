import MainHeader from "../header/header";

const BankOfferTermsAndConditionsDesktop = () => {
    const handleButtonClick = () => {
        window.location.href = 'https://outlook.office365.com/owa/calendar/SwishClubDemo@swishclub.in/bookings/s/oUoszJmqSE-Nmmfn9rbJIA2';
    };
    return (
        <div>
            <link rel="stylesheet" type="text/css" href="/TermsOfService.css"/>
            <div className="terms-of-service" style={{height: '840px'}}>
                <div className="group-parent1">
                    <div className="group-parent2">
                        <div className="rectangle-parent4">
                            <div className="rectangle-signin"/>
                            <b className="contact-us1">SIGN IN</b>
                        </div>
                    </div>
                    <a href={"/"}>
                        <img className="swish-logo-1" alt="" src="/swish-logo-1.svg"/>
                    </a>
                </div>
                <div className="terms-of-service-inner">
                    <div className="group-div">
                        <div className="group-item"/>
                        <div className="social-button-parent">
                            {/* <img
                                className="social-button-icon"
                                alt=""
                                src="/termsOfServices/social-button.svg"
                            /> */}
                            <div className="social-button">
                                <a href="https://twitter.com/swishclubhq">
                                    <img className="twitter-icon" alt="" src="/Twitter.png"/>
                                </a>
                                <a href="https://www.linkedin.com/company/swishclubhq/about/">
                                    <img className="google-icon" alt="" src="/linkInd.png"/>
                                </a>
                                <a href="https://www.instagram.com/swishclubhq/?hl=en">
                                    <img className="facebook-icon" alt="" src="/instagram.png"/>
                                </a>
                            </div>
                            <a href={"/"}>
                                <img className="swish-logo-11" alt="" src="/termsOfServices/swish-logo-11.svg"/>
                            </a>
                            <div className="copyright-2023-container">
                                <p className="copyright-2023-swish">
                                    <span className="copyright-2023">Copyright © 2023 Swish</span>
                                </p>
                                <p className="copyright-2023-swish">
                                    <span>
                                        <span
                                            className="benplat-epp-technology">{` BenPlat EPP Technology Private Limited |  `}</span>
                                        {/* <a className="terms-of-service1" href={"/terms"} style={{ "textDecoration": "none", color: "#CAC7C7" }}>Terms Of Service</a> */}
                                        <span className="benplat-epp-technology">
                                            {" "}
                                            All rights reserved
                                        </span>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="this-document-is-container"
                     style={{overflow: "scroll", height: "550px", marginTop: "-120px", marginBottom: "0px"}}>

                    <h1 className="">Bank Offers Terms And Conditions</h1>
                    <div className="bank_offer_mob vertical_btnc">
                        <p className="heading_btnc_mob">Federal Bank: Credit card offer</p>

                        <ul className="content_btnc_mob">
                            <li>
                                <p>10% Instant Discount upto Rs1000 on Federal Bank Credit Cards, on full swipe transactions only</p>
                            </li>
                            <li>
                                <p>Offer applicable on a minimum purchase of Rs. 5,000.</p>
                            </li>
                            <li>
                                <p>Offer from 1st Sep, 2024 to 30th Sep, 2024 (both days inclusive)</p>
                            </li>
                            <li>
                                <p>Offer is applicable only once per customer per eligible card</p>
                            </li>
                            <li>
                                <p>Benplat EPP Technology Pvt Ltd reserves the right to change / alter / revoke
                                    aforesaid offer without prior notice</p>
                            </li>
                            <li>
                                <p>By participating in this Offer, Customers/Cardholders agree to be bound by these
                                    terms & conditions and waive any right to claim ambiguity in these terms &
                                    conditions. Cardholders agree to release, discharge, indemnify and hold harmless
                                    the Banks, Benplat EPP Tech pvt ltd , and their respective directors, and employees
                                    from and against any loss, claims, damages or liability resulting in whole or in
                                    part, directly or indirectly, from acceptance and/or participation in the Offer.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BankOfferTermsAndConditionsDesktop;
